import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";

const Footer = ({ className, ...props }) => {
  className = cx(
    "w-full px-8 py-4 text-white bg-gray-800 flex flex-col md:flex-row justify-between items-start md:items-center",
    className
  );
  return (
    <>
      <div className={className} {...props}>
        <div className="flex-initial text-xl font-semibold">Smart Fleet</div>
        <div>
          <ul className="flex flex-col md:flex-row text-sm -mx-3 font-medium">
            <li className="mx-3"><Link to="/">Home</Link></li>
            <li className="mx-3"><Link to="/contact">Contact</Link></li>
            <li className="mx-3"><Link to="/over-ons">Over ons</Link></li>
            <li className="mx-3"><Link to="/diensten">Diensten</Link></li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-black flex flex-col sm:flex-row justify-between items-start md:items-center">
        <div>
          <strong className="font-bold">Telefoon: </strong>
          <a href="tel:03.430.91.62">03.430.91.62</a>

          <strong className="ml-3 font-bold">Email: </strong>
          <a href="mailto:info@smart-fleet.be">info@smart-fleet.be</a>
          <strong className="ml-3 font-bold">Adres: </strong> Pastorijveld 114, 2180 Ekeren

        </div>
        <div className="flex flex- items-center">
          <a className="mr-1" href="https://www.facebook.com/SmartFleetbv/?ref=aymt_homepage_panel&eid=ARDcVAXW5JpoleLNfXM2LkrLnga7AIVkYIjUHO7ldl-JZe538z-B26hpi5jfuz3GlXUVV2vMzDMq5zlz"><FaFacebookSquare/></a>
          <a className="mr-1" href="https://www.linkedin.com/company/smart-fleet-bv/?viewAsMember=true"><FaLinkedin/></a>
          <Link to="/" className="hover:underline">
            www.smartfleet.be
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;

